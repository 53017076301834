import React, { useState, version } from 'react';
import { GetBreakChannelTypeAPI } from 'api';
import { DataGrid } from 'op2mise-react-widgets';
import { useCallbackPrompt } from '../_reusable/blocker/UsePromptComponent.ts';
import Button from 'shared/components/button/Button';
import Input from 'shared/components/input/Input';
import Switch from 'shared/components/switch/Switch';
import { uploadFileIcon, downloadFileIcon, disabledDownloadFile } from '../../assets/index';
import './BreakPatterns.css';
import useStore from 'store/AccountStore';
import ImportFileModalComponent from './modals/ImportFileModalComponent';
import BreakPatternForms from './BreakPatternForms';
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import useNotification from 'store/NotificationStore';
import {
  SaveBreakChannelTemplateAPI,
  ExportBreakChannelTypeAPI,
  GetBreakChannelEventListAPI
} from 'api';
import { padStart } from '@fullcalendar/react';
import Select from 'shared/components/select/Select.jsx';
import OpTimeInput from 'op2mise-react-widgets/OpTimeInput/OpTimeInput.jsx';
import { NumericTextbox, DatePicker } from 'op2mise-react-widgets/index.js';
import { Constants } from './constants.js';
import { debounce } from '@syncfusion/ej2-base';
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal.jsx';
import moment from 'moment';

const BreakPatternEditor = ({
  mode,
  setMode,
  onClose,
  onImport,
  selectedRow,
  setSelectedRow,
  channelInfo,
  setIsDirty,
  isDirty,
}) => {
  const { user } = useStore((state) => state);
  const { success } = useNotification((state) => state);
  const [isDirtyForm, setDirtyForm] = React.useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = React.useState(
    false
  );
  const { sampleUploadFile, importModalComponents } = BreakPatternForms;
  const [uploadState, setUploadState] = React.useState({
    modalComponent: importModalComponents.import,
    errorMessage: '',
    resultList: [],
    show: false,
  });
  const { events } = Constants;
  const { channelId, channelName } = channelInfo;
  const { contentLevel } = selectedRow;
  const { eventType, eventName, eventDurationFrom, eventDurationTo, timeOfDayFrom, timeOfDayTo, periodFrom, periodTo } = contentLevel;

  const [eventDuration, setEventDuration] = useState({
    start: eventDurationFrom,
    end: eventDurationTo
  });
  const [timeOfDay, setTimeOfDay] = useState({
    start: timeOfDayFrom ? moment(timeOfDayFrom, "HH:mm:ss").toDate() : null,
    end: timeOfDayTo ? moment(timeOfDayTo, "HH:mm:ss").toDate() : null
  });
  const [period, setPeriod] = useState({
    start: periodFrom,
    end: periodTo
  });
  const [showError, setShowError] = useState(false);
  const [contentLevelFields, setContentLevelFields] = useState({
    eventType: eventType,
    eventName: eventName, //mandatory when eventType is selected
  });
  const [patternData, setPatternData] = useState({
    id: selectedRow.id ?? null,
    versionName: selectedRow.version ?? '',
    status: selectedRow.status === 'Active' ? true : false,
    default: selectedRow.default,
    breakChannelTypes: [],
  });

  const [eventNameList, setEventNameList] = useState([]);

  const [sortingResult, setSortingResult] = useState({
    ascending: true,
  });

  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ] = useCallbackPrompt(isDirtyForm);

  // Input properties
  const props = React.useMemo(() => ({
    labelStyle: {
      fontWeight: 'bold',
      color: '#6D6D73',
    },
    name: 'versionName',
    type: 'text',
    placeholder: 'Enter pattern name',
    value: patternData.versionName,
    errorMessage: '',
    maxLength: 99,
    isRequired: false,
    width: '70%',
    onChange: (e) => {
      handleInputChange(e, 'patterName')
    },
    onFocus: () => { },
    onBlur: () => { },
    onClick: () => { },
    disabled: false,
    pattern: '',
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '20px',
    },
    textAlign: 'left',
  }));
  // Switch properties
  const activeSwitchProps = React.useMemo(
    () => ({
      label: 'Active',
      labelStyle: {
        fontWeight: 'bold',
        color: '#6D6D73',
      },
      text: ['No', 'Yes'],
      active: patternData.status,
      disabled: patternData.breakChannelTypes.length === 0 || patternData.default,
      onSwitch: (e) => {
        setPatternData((prev) => ({ ...prev, status: e }));
      },
      blurredOnInactive: false,
    }),
    [patternData.breakChannelTypes, patternData.status, patternData.default]
  );

  const defaultSwitchProps = React.useMemo(() => ({
    label: 'Default',
    labelStyle: {
      fontWeight: 'bold',
      color: '#6D6D73',
    },
    text: ['No', 'Yes'],
    active: patternData.default ?? selectedRow?.default === 'Yes',
    // disabled: patternData.breakChannelTypes?.length === 0,
    onSwitch: (e) => {
      if (e) {
        setPatternData((prev) => ({ ...prev, default: e, status: e }));
        setContentLevelFields({
          eventType: null,
          eventName: null,
        })
        setEventDuration({ start: null, end: null })
        setTimeOfDay({ start: null, end: null })
        setPeriod({ start: null, end: null })
      } else {
        setPatternData((prev) => ({ ...prev, default: e }));
        if ([patternData.breakChannelTypes.length === 0])
          setPatternData((prev) => ({ ...prev, status: e }));

      }
    },
    blurredOnInactive: false,
  }), [patternData.breakChannelTypes])

  // Column definitions for the data grid
  const columnDefinitions = React.useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        headerTooltip: 'Type',
        filterParams: {
          valueFormatter: (params) => {
            return params.value.charAt(0).toUpperCase() + params.value.slice(1);
          },
        },
        valueFormatter: (params) => {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        },
      },
      {
        field: 'slotPosition',
        headerName: 'Slot Position',
        headerTooltip: 'Slot Position',
      },
      {
        field: 'optional',
        headerName: 'Optional',
        headerTooltip: 'Optional',
        cellDataType: 'text',
        filterParams: {
          valueFormatter: (params) => {
            (params.value ? 'Yes' : 'No')
          },
        },
        valueFormatter: (params) => {
          (params.value ? 'Yes' : 'No')
        },
      },
      {
        field: 'position',
        headerName: 'Position',
        headerTooltip: 'Position',
        sort: 'asc',
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'selection',
        headerName: 'Selection',
        headerTooltip: 'Selection',
        filterParams: {
          valueFormatter: (params) => {
            return params.value.charAt(0).toUpperCase() + params.value.slice(1);
          },
        },
        valueFormatter: (params) => {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        },
      },
      {
        field: 'variable',
        headerName: 'Variable',
        headerTooltip: 'Variable',
        cellDataType: 'text',
        filterParams: {
          valueFormatter: (params) => {
            return params.value ? 'Yes' : 'No';
          },
        },
        valueFormatter: (params) => {
          return params.value ? 'Yes' : 'No';
        },
      },
      // {
      //   field: 'externalID',
      //   headerName: 'Asset',
      //   headerTooltip: 'Asset',
      // },
      {
        field: 'eventName',
        headerName: 'Event',
        headerTooltip: 'Event',
      },
      {
        field: 'priority',
        headerName: 'Priority',
        headerTooltip: 'Proirity',
        cellStyle: { textAlign: 'right' },
      },
    ],
    []
  );

  // Returns all fields from columnDefinition except the action column
  const headerFields = columnDefinitions
    .filter((column) => column.field !== 'action')
    .map((column) => column.field);

  const defaultSortingResult = () => {
    setSortingResult({
      ...sortingResult,
      ...headerFields.reduce((column, field) => {
        column[field] = false;
        return column;
      }, {}),
    });
  };

  const fetchChannelTemplate = (templateId) => {
    GetBreakChannelTypeAPI({
      queryParams: { breakChannelTemplateId: templateId },
      onSuccess: (res) => {
        // setVersionData(res);
        setPatternData((prev) => ({ ...prev, breakChannelTypes: res }))
        defaultSortingResult();
      },
    });
  };

  const changeImportModal = (attribute, value) => {
    setUploadState((prevState) => ({
      ...prevState,
      [attribute]: value,
    }));
  };

  const handeImportFile = () => {
    if (patternData.breakChannelTypes.length > 0) {
      setUploadState({
        ...uploadState,
        modalComponent: importModalComponents.confirm,
      });
      setDirtyForm(true);
    } else {
      confirmImportFile();
    }
  };

  const confirmImportFile = async () => {
    setUploadState({
      ...uploadState,
      modalComponent: importModalComponents.loading,
    });
    var res = await onImport(uploadState.selectedFile);
    if (res.status == 200) {
      setUploadState({
        ...uploadState,
        resultList: res.data.items,
        modalComponent: importModalComponents.success,
      });
      setDirtyForm(true);
      const importedData = res.data.items
        .filter((o) => o.imported)
        .map((value, index) => {
          return {
            id: index,
            type: value.type,
            slotPosition: value.slotPosition,
            optional: value.optional === 'Yes' ? true : false,
            priority: value.priority,
            selection: value.selection,
            variable: value.variable === 'Yes' ? true : false,
            externalID: value.externalID,
            eventName: value.eventName,
            position: parseInt(value.position),
            assetID: parseInt(value.assetID),
          };
        });
      setPatternData((prev) => ({ ...prev, breakChannelTypes: importedData }));
    }
  };

  const handleUploadModal = () => {
    setUploadState({
      errorMessage: '',
      resultList: [],
      modalComponent: importModalComponents.import,
      show: true,
    });
  };

  const handleExportSampleTemplate = () => {
    const excelData = sampleUploadFile;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileName = `break-pattern-import-template.xlsx`;
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { errors: ws }, SheetNames: ['errors'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  const handleDownload = () => {
    if (patternData.breakChannelTypes.length > 0) {
      ExportBreakChannelTypeAPI({
        queryParams: {
          breakChannelTemplateId: selectedRow.id,
          payload: { sort: sortingResult },
        },
        onSuccess: (res) => {
          const { organisationName } = user;
          let exportDate = new Date();
          const exportedDateString =
            padStart(exportDate.getDate(), 2).toString() +
            padStart(exportDate.getMonth() + 1, 2).toString() +
            exportDate.getFullYear().toString();
          const fileName = `${organisationName}_op2mise_break_patterns_export_${channelName}_${exportedDateString}.xlsx`;
          const blob = new Blob([res], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, fileName);
        },
      });
    }
  };
  const incompleteEventFields = (contentLevelFields.eventType?.length && !contentLevelFields.eventName?.length)
  const areContentLevelFieldsNull = () => {
    const fields = {
      ...contentLevelFields,
      assedID: null,
      eventDurationFrom: eventDuration.start,
      eventDurationTo: eventDuration.end,
      timeOfDayFrom: timeOfDay.start,
      timeOfDayTo: timeOfDay.end,
      periodFrom: period.start,
      periodTo: period.end
    }

    return Object.values(fields).every(value => value === null || value === undefined)
  }
  const saveBreakChannelTemplate = () => {
    let data = {};
    let patterns = [];

    if (((!areContentLevelFieldsNull() && !incompleteEventFields) && !patternData.default) || patternData.default) {
      setShowError(false)
      if (patternData.breakChannelTypes.length > 0) {
        patterns = patternData.breakChannelTypes.map((pattern) => {
          return {
            type: pattern.type ?? '',
            slotPosition: pattern.slotPosition,
            optional: pattern.optional,
            priority: !pattern.priority ? 0 : pattern.priority,
            selection: pattern.selection,
            isVariableLength: pattern.variable,
            eventName: pattern.eventName,
            position: pattern.position,
            externalID: pattern.externalID,
            assetID: pattern.assetID,
          };
        });
      }
      if (mode === 'new') {
        data = {
          id: 0,
          versionName: patternData.versionName,
          active: patternData.status,
          default: patternData.default,
          contentLevel: {
            ...contentLevelFields,
            assedID: null,
            eventDurationFrom: eventDuration.start,
            eventDurationTo: eventDuration.end,
            timeOfDayFrom: timeOfDay.start ? moment(timeOfDay.start).format('HH:mm:ss') : null,
            timeOfDayTo: timeOfDay.end ? moment(timeOfDay.end).format('HH:mm:ss') : null,
            periodFrom: period.start,
            periodTo: period.end
          },
          breakChannelTypes: patterns,
        };
      } else if (mode === 'edit') {
        if (selectedRow) {
          data = {
            id: selectedRow.id,
            versionName: patternData.versionName,
            active: patternData.status,
            default: patternData.default,
            contentLevel: {
              ...contentLevelFields,
              assedID: null,
              eventDurationFrom: eventDuration.start,
              eventDurationTo: eventDuration.end,
              timeOfDayFrom: timeOfDay.start ? moment(timeOfDay.start).format('HH:mm:ss') : null,
              timeOfDayTo: timeOfDay.end ? moment(timeOfDay.end).format('HH:mm:ss') : null,
              periodFrom: period.start,
              periodTo: period.end
            },
            breakChannelTypes: patterns,
          };
        }
      }
      setSelectedRow({
        ...data,
        version: patternData.versionName,
        active: patternData.status ? 'Active' : patternData.status,
      });

      SaveBreakChannelTemplateAPI({
        queryParams: { breakChannelTemplateId: channelId, payload: data },
        onSuccess: (res) => {
          success('Successfully saved!');
          setDirtyForm(false);
          setShowConfirmLeaveModal(false);
          setIsDirty(true); // to update the GetBreakChannelTemplateAPI from the Parent Component
        },
      });
    } else {
      setShowError(true)
    }
  };

  const debouncedSaveBreakChannelTemplate = debounce(saveBreakChannelTemplate, 300);

  const handleEventNameChange = (type) => {
    const types = {
      'Title Group': 'titlegroup',
      'Series': 'series',
      'Season': 'season',
      'Program': 'program',
    }
    GetBreakChannelEventListAPI({
      queryParams: {
        channelId: channelInfo.channelId,
        type: types[type],
      },
      onSuccess: (res) => {
        let list = res.eventList;
        if (list.length) {
          list[list.length + 1] = { id: list.length + 1, name: "Clear selection" }
        }
        setEventNameList(list);
      },
    });
  };

  const handleDiscardChanges = () => {
    if (isDirtyForm) setShowConfirmLeaveModal(false);
    if (showPrompt) cancelNavigation();
    onClose('view');
  };

  const handleDisableOnSaveButton = () => {
    if (mode === 'new') {
      return patternData.breakChannelTypes?.length === 0
        || patternData.versionName?.length === 0;
    } else {
      return !isDirtyForm;
    }
  };

  const handleInputChange = (event, type, field) => {
    const value = type !== 'timeOfDay' ? event.target.value : event;
    const isStart = field === 'start'
    switch (type) {
      case 'patterName':
        setPatternData((prev) => ({
          ...prev,
          versionName: value
        }))
        break;
      case 'duration':
        if (isStart)
          setEventDuration((prev) => ({ ...prev, start: value }))
        else
          setEventDuration((prev) => ({ ...prev, end: value }))
        break;
      case 'timeOfDay':
        if (isStart)
          setTimeOfDay((prev) => ({ ...prev, start: value }))
        else
          setTimeOfDay((prev) => ({ ...prev, end: value }))
        break;
      case 'period':
        if (isStart)
          setPeriod((prev) => ({ ...prev, start: value }))
        else
          setPeriod((prev) => ({ ...prev, end: value }))
        break;
    }

    // Create a new debounced callback if the component re-renders
    let timeoutId;
    timeoutId = setTimeout(() => {
      debouncedCallback(value);
    }, 300);

    // Clear the previous timeout if it exists
    clearTimeout(timeoutId);
  };

  const ErrorMessage = (
    message = 'At least one content level is required',
    show = false,
  ) => {
    return (<>
      {(showError || show) && <small className='error-message'>{message}</small>}
    </>)
  }

  React.useEffect(() => {
    if (mode === 'new') {
      if (
        (patternData.versionName?.length > 0
          && (patternData.status !== (selectedRow.status !== 'Active')) ||
          patternData.breakChannelTypes?.length > 0)
      ) {
        setDirtyForm(true);
      } else {
        setDirtyForm(false);
      }
    } else {
      if (patternData.versionName === selectedRow.version
        && patternData.status === (selectedRow.status === 'Active')
        && patternData.default === selectedRow.default) {
        setDirtyForm(false);
        // if any content level field state changes, set dirty form to true
        if (patternData.default === false) {
          if (contentLevelFields.eventType === eventType
            && contentLevelFields.eventName === eventName
            && eventDuration.start === eventDurationFrom
            && eventDuration.end === eventDurationTo
            && (timeOfDay.start ? moment(timeOfDay.start, "HH:mm:ss").format("HH:mm:ss") : null) === timeOfDayFrom
            && (timeOfDay.end ? moment(timeOfDay.end, "HH:mm:ss").format("HH:mm:ss") : null) === timeOfDayTo
            && period.start === periodFrom
            && period.end === periodTo) {
            // Set event name list if event type is selected
            if (contentLevelFields.eventType) {
              handleEventNameChange(contentLevelFields.eventType)
            }
            setDirtyForm(false);
          } else {
            setDirtyForm(true);
          }
        }
      } else {
        setDirtyForm(true);
      }
    }
  }, [
    patternData.status,
    patternData.versionName,
    selectedRow,
    patternData.default,
    patternData.breakChannelTypes.length,
    contentLevelFields.eventType,
    contentLevelFields.eventName,
    eventDuration.start,
    eventDuration.end,
    timeOfDay.start,
    timeOfDay.end,
    period.start,
    period.end
  ]);

  React.useEffect(() => {
    if (selectedRow.id || isDirty) {
      if (mode === 'new') {
        setMode('edit');
      }
      fetchChannelTemplate(selectedRow.id);
    }
  }, [selectedRow?.id, isDirty]);

  return (
    <div className="break-editor-wrapper">
      <div className='break-editor-form-fields'>
        <div className='break-editor-form-grid'>
          <div className='break-editor-field'>
            <label>Pattern Name *</label>
            <Input {...props} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Switch {...activeSwitchProps} />
            <Switch {...defaultSwitchProps} />
            <div className="break-import-export">
              <img
                src={uploadFileIcon}
                alt=""
                title="Import pattern"
                onClick={handleUploadModal}
                style={{
                  cursor: 'pointer',
                }}
              />

              {mode === 'new' ?
                (
                  <img
                    src={disabledDownloadFile}
                    alt=""
                    title="Export pattern"
                    onClick={handleDownload}
                    style={{
                      cursor: 'not-allowed',
                      height: '28px'
                    }}
                  />
                )
                : (
                  <img
                    src={downloadFileIcon}
                    alt=""
                    title="Export pattern"
                    onClick={handleDownload}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )}
            </div>
          </div>
        </div>
        <label>Content Level</label>
        <div className='break-editor-form-grid'>
          <div className='left-fields'>
            <div className="break-editor-field">
              <label>Type</label>
              <div>
                <Select
                  text={contentLevelFields.eventType ? contentLevelFields.eventType : 'Please select a type'}
                  list={events}
                  noSearch={true}
                  onSelect={(args) => {
                    if (args.name !== 'Clear selection') {
                      handleEventNameChange(args.name)
                      setContentLevelFields((prev) => ({
                        ...prev,
                        eventType: args.name,
                        // Reset event name when event type changes
                        eventName: null
                      }))
                    } else {
                      handleEventNameChange(null)
                      setContentLevelFields((prev) => ({
                        ...prev,
                        eventType: null,
                        // Reset event name when event type changes
                        eventName: null
                      }))
                    }

                  }}
                  width="26.5vw"
                  disabled={patternData.default}
                />
                {ErrorMessage(incompleteEventFields && '')}
              </div>
            </div>
            <div className="break-editor-field">
              <label>Name</label>
              <div>
                <Select
                  text={contentLevelFields.eventName ? contentLevelFields.eventName : 'Please select a name'}
                  list={eventNameList}
                  onSelect={(args) => {
                    if (args.name !== 'Clear selection') {

                      setContentLevelFields((prev) => ({
                        ...prev, eventName: args.name
                      }))
                    } else {
                      setContentLevelFields((prev) => ({
                        ...prev, eventName: null
                      }))
                    }
                  }}
                  width="26.5vw"
                  disabled={patternData.default}
                />
                {contentLevelFields.eventType?.length && !contentLevelFields.eventName?.length
                  ? ErrorMessage('Name is required', true)
                  : ErrorMessage(incompleteEventFields && '')}
              </div>
            </div>
            <div>
              <div className='break-editor-field'>
                <label>Duration</label>
                <div className='content-level-input dual-input'>
                  <NumericTextbox
                    label='From'
                    min={1}
                    max={999}
                    onChange={(e) => {
                      handleInputChange(e, 'duration', 'start')
                    }}
                    showSpinButton={false}
                    format={'##'}
                    cssClass="e-op2mise-numeric-textbox"
                    placeholder='Min.'
                    style={{
                      textAlign: 'left', padding: '0 10px'
                    }}
                    value={eventDuration.start}
                    enabled={!patternData.default}
                  />
                  <NumericTextbox
                    label='To'
                    min={1}
                    max={999}
                    onChange={(e) => {
                      handleInputChange(e, 'duration', 'end')
                    }}
                    showSpinButton={false}
                    format={'##'}
                    cssClass="e-op2mise-numeric-textbox"
                    placeholder='Min.'
                    style={{ textAlign: 'left', padding: '0 10px' }}
                    value={eventDuration.end}
                    enabled={!patternData.default}
                  />
                </div>
              </div>
              {ErrorMessage(incompleteEventFields && '')}
            </div>
          </div>
          <div className="right-fields">
            <div>
              <div className="break-editor-field">
                <label>Time of Day</label>
                <div className='content-level-input dual-input'>
                  <OpTimeInput
                    label='From'
                    value={timeOfDay.start}
                    onChange={(e) => handleInputChange(e, 'timeOfDay', 'start')
                    }
                    width="full"
                    min={'00:00'}
                    max={'24:00'}
                    style={{ width: '100%' }}
                    direction='row'
                    enabled={!patternData.default}
                  />
                  <OpTimeInput
                    value={timeOfDay.end}
                    label='To'
                    onChange={(e) => handleInputChange(e, 'timeOfDay', 'end')}
                    width="full"
                    style={{ width: '100%' }}
                    direction='row'
                    min={'00:00'}
                    max={'24:00'}
                    enabled={!patternData.default}
                  />
                </div>
              </div>
              {ErrorMessage(incompleteEventFields && '')}
            </div>
            <div>
              <div className="break-editor-field">
                <label>Period</label>
                <div className='content-level-input dual-input'>
                  <DatePicker
                    allowEdit={false}
                    cssClass="view-schedules-date-picker"
                    format={'dd/MM/yyyy'}
                    label="From"
                    onChange={(args) => {
                      handleInputChange(args, 'period', 'start')
                    }}
                    showClearButton={false}
                    placeholder="dd/MM/yyyy"
                    value={period.start}
                    enabled={!patternData.default}
                  />
                  <DatePicker
                    allowEdit={false}
                    cssClass="view-schedules-date-picker"
                    format={'dd/MM/yyyy'}
                    label="To"
                    onChange={(args) => {
                      handleInputChange(args, 'period', 'end')
                    }}
                    showClearButton={false}
                    placeholder="dd/MM/yyyy"
                    value={period.end}
                    enabled={!patternData.default}
                  />
                </div>
              </div>
              {ErrorMessage(incompleteEventFields && '')}
            </div>
          </div>
        </div>

      </div>
      <DataGrid
        columns={columnDefinitions}
        customVerbiage="No break patterns found"
        enableMultipleRowSelection={false}
        enableMultipleColumnSorting={true}
        gridHeightBuffer={showError || incompleteEventFields ? 595 : 545}
        gridName="channelSetup-breakPatterns-editor"
        onStateUpdated={(params) => {
          if (params?.length > 0) {
            let sortRes = params.reduce((column, field) => {
              column[field.colId] = field.sort === 'asc' ? true : false;
              return column;
            }, {});
            setSortingResult({
              ...sortingResult,
              ...sortRes,
            });
          }
        }}
        resizable
        rows={patternData.breakChannelTypes}
        sharedColumnProperties={{
          // cellStyle: { fields: headerFields, value: { textAlign: 'left' } },
          flex: { fields: headerFields, value: 1 },
          sortable: { fields: headerFields, value: true },
          filter: { fields: headerFields, value: true },
        }}
        showAlternativeRowHighlight
        suppressRowClickSelection={true}
      />
      <div className="break-editor-footer">
        <Button
          text="Close"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#6d6d73',
          }}
          onClick={() => {
            if (isDirtyForm) setShowConfirmLeaveModal(true);
            else onClose('view')
          }}
        />
        <Button
          text="Save"
          onClick={debouncedSaveBreakChannelTemplate}
          disabled={handleDisableOnSaveButton()}
        />
      </div>
      <ImportFileModalComponent
        closeModal={() => {
          setUploadState({ ...uploadState, show: false });
        }}
        selectedFile={uploadState.selectedFile}
        setSelectedFile={(e) => changeImportModal('selectedFile', e)}
        onImportFile={handeImportFile}
        errorMessage={uploadState.errorMessage}
        showModal={uploadState.show}
        onExportSampleTemplate={handleExportSampleTemplate}
        modalComponent={uploadState.modalComponent}
        onProceed={confirmImportFile}
        resultList={uploadState.resultList}
      />

      <ConfirmLeaveModal
        show={showPrompt}
        onSave={() => {
          debouncedSaveBreakChannelTemplate();
          confirmNavigation();
        }}
        onDiscard={confirmNavigation}
        onHide={() => { cancelNavigation() }}
      />

      <ConfirmLeaveModal
        show={showConfirmLeaveModal}
        onHide={() => {
          setShowConfirmLeaveModal(false);
        }}
        onSave={() => {
          debouncedSaveBreakChannelTemplate();
          onClose('view');
        }}
        onDiscard={handleDiscardChanges}
      />

    </div>
  );
};

export default BreakPatternEditor;
