export const OverlayTemplate = ({ customVerbiage }) => {
  return (
    <div className="ag-overlay-no-rows-wrapper">
        <div className="ag-overlay-no-rows-panel">
          {typeof customVerbiage !== 'function' ? (
          <span className="ag-overlay-no-rows-center">{customVerbiage}</span>
          ) : (
            customVerbiage()
          )}
        </div>
    </div>
  );
}
