import * as React from 'react';
import {
  GetChannelLookupAPI,
  SaveChannelArchitectureAPI,
  SaveGradeArchitectureAPI,
  SaveMinutageArchitectureAPI
} from 'api';
import CAGrading from './CAGrading';
import CAMinutage from './CAMinutage';
import CAProgrammmes from './CAProgrammes';
import { NumericTextbox } from 'op2mise-react-widgets';
import Header from 'shared/components/header/Header';
import Select from 'shared/components/select/Select';
import {
  addDaysToDate,
  getAllLastAccessed,
  getMondayOfTheWeek,
  saveAllLastAccessed,
} from 'utils';
import useStore from 'store/AccountStore';
import './ChannelArchitecture.css';
import { useCallbackPrompt } from 'components/_reusable/blocker/UsePromptComponent.ts';
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal';
import { schedulesReducer } from './reducers/schedules.reducer';
import useNotification from 'store/NotificationStore';
import { calculateDates } from 'utils';
import moment, { weekdays } from 'moment';
import { getBlockName } from './utils/architecture.utils';
import { Internationalization } from '@syncfusion/ej2-base';
import { Constants } from './helper/constants';

const ChannelArchitecture = () => {
  const { user } = useStore((state) => state);
  const { weekdays } = Constants;
  const [activatedTab, setActivatedTab] = React.useState('Program');
  const [channelsList, setChannelsList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [numberOfWeeks, setNumberOfWeeks] = React.useState(1);
  const [quickInfoDetails, setQuickInfoDetails] = React.useState({
    header: null,
    content: null,
  });
  const [selectedChannel, setSelectedChannel] = React.useState(0);
  const [channelInfo, setChannelInfo] = React.useState({
    channelId: 0,
    channelName: null,
  });
  const [startDate, setStartDate] = React.useState(
    getMondayOfTheWeek(new Date())
  );
  const [endDate, setEndDate] = React.useState(
    addDaysToDate(startDate, 7)
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const { success, danger } = useNotification((state) => state);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isDirty
  );
  const [schedules, dispatch] = React.useReducer(schedulesReducer, {});
  const [showDirtyForm, setShowDirtyForm] = React.useState(false);
  const [next, setNext] = React.useState();
  const [grading, setGrading] = React.useState({});
  const [minutage, setMinutage] = React.useState({});
  const [allEvents, setAllEvents] = React.useState({});

  const tabOptions = ['Program', 'Grading', 'Minutage'];
  const getSession = (channels) => {
    const session = getAllLastAccessed();
    const {
      organization,
      activeFunction,
      menuItem,
      channelId,
      channelName,
      tab,
    } = session;
    if (channelId && channelName) {
      if (
        channels.find((channel) => channel.channelId === channelId)?.channelName
      ) {
        setSelectedChannel(channelId);
        setChannelInfo({ channelId: channelId, channelName: channelName });
      }

      if (tab && tabOptions.includes(tab)) {
        setActivatedTab(tab);
      } else {
        saveAllLastAccessed({ tab: tabOptions[0] });
      }
    } else {
      if (tab) saveAllLastAccessed({ tab: activatedTab });
    }
  };

  const getChannelsAPI = () => {
    GetChannelLookupAPI({
      onSuccess: (res) => {
        if (res.length > 0) {
          setChannelsList(res);
          //getSession(res);
        }
      },
      // setLoader: setIsLoading,
    });
  };

  const handleSaveChannelArchitecture = React.useCallback((events) => {
    let blocks = []
    if (events.length) {
      blocks = events.map((schedule) => {
        return {
          availableDuration: schedule?.AvailableDuration ? schedule.AvailableDuration : 0,
          blockName: getBlockName(schedule.DayOfWeek, schedule.StartTime),
          blockReference: schedule?.BlockReference,
          channelID: channelInfo.channelId,
          dayOfWeek: schedule.DayOfWeek,
          endTime: moment(schedule.EndTime, "HH:mm:ss").format("HH:mm:ss"),
          genre: schedule?.Genre ? schedule.Genre : "",
          layout: schedule?.Layout,
          link: schedule?.IsLinked ? schedule.IsLinked : false,
          maxCount: schedule?.MaxCount ? schedule.MaxCount : 0,
          seasonID: schedule?.SeasonID ? schedule.SeasonID.toString() : null,
          seriesID: schedule?.SeriesID ? +schedule.SeriesID : null,
          seriesName: schedule?.SeriesName ? schedule.SeriesName : null,
          sequential: false,
          startTime: moment(schedule.StartTime, "HH:mm:ss").format("HH:mm:ss"),
          titleGroupID: schedule?.TitleGroupID ? +schedule.TitleGroupID : null,
          titleGroupName: schedule?.TitleGroupName ? schedule.TitleGroupName : null,
          type: schedule?.Type ? schedule?.Type : 'Movies',
          week: schedule?.Week,
          id: isNaN(schedule?.Id) ? 0 : schedule.Id,
        }
      }).filter(block => block.week === 1)
    }
    console.log("block", blocks)
    SaveChannelArchitectureAPI({
      queryParams: {
        channelId: channelInfo.channelId,
        payload: { blocks: blocks },
      },
      onSuccess: (res) => {
        if (res) {
          if (showDirtyForm) {
            handleDiscardDirtyForm()
          }
          success('Successfully saved!');
          setIsDirty(false);
        }
      },
    });
  }, [channelInfo]);

  const handleSaveGradeArchitecture = React.useCallback((grading) => {
    let blocks = []

    if (grading.length) {
      const removedAdjustedSchedules = grading.filter((o) => !isNaN(o.Id))
      blocks = removedAdjustedSchedules.map((schedule) => {
        return {
          id: isNaN(schedule?.Id) ? 0 : schedule.Id,
          channelID: channelInfo.channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: moment(schedule.StartTime, "HH:mm:ss").format("HH:mm:ss"),
          endTime: moment(schedule.EndTime, "HH:mm:ss").format("HH:mm:ss"),
          grade: schedule?.Grade ? schedule.Grade : 0,
          value: 0,
          miutage: 0,
        }
      })
    }
    SaveGradeArchitectureAPI({
      queryParams: {
        channelId: channelInfo.channelId,
        payload: { gradeMinutageArchitectures: blocks },
      },
      onSuccess: (res) => {
        if (showDirtyForm) {
          handleDiscardDirtyForm()
        }
        success('Successfully saved!');
        setIsDirty(false);
      },
    });

  }, [channelInfo]);

  const handleSaveMinutageArchitecture = React.useCallback((minutage) => {
    let blocks = []

    if (minutage.length) {
      const instance = new Internationalization();
      const removedAdjustedSchedules = minutage.filter((o) => !isNaN(o.Id))

      blocks = removedAdjustedSchedules.map((schedule) => {
        const day = instance.formatDate(schedule.StartTime, { skeleton: 'E' })
        return {
          id: isNaN(schedule?.Id) ? 0 : schedule.Id,
          channelID: channelInfo.channelId,
          dayOfWeek: weekdays[day],
          startTime: moment(schedule.StartTime, "HH:mm:ss").format("HH:mm:ss"),
          endTime: moment(schedule.EndTime, "HH:mm:ss").format("HH:mm:ss"),
          grade: 0,
          value: 0,
          minutage: schedule?.Minutage ? schedule.Minutage : 0,
        }
      })
    }
    SaveMinutageArchitectureAPI({
      queryParams: {
        channelId: channelInfo.channelId,
        payload: { minutageArchitectures: blocks },
      },
      onSuccess: (res) => {
        if (showDirtyForm) {
          handleDiscardDirtyForm()
        }
        success('Successfully saved!');
        setIsDirty(false);
      },
    });

  }, [channelInfo]);

  const handleSaveDirty = React.useCallback(() => {
    switch (activatedTab) {
      case 'Program':
        handleSaveChannelArchitecture(allEvents);
        break;
      case 'Grading':
        handleSaveGradeArchitecture(grading);
        break;
      case 'Minutage':
        handleSaveMinutageArchitecture(minutage)
        break;
    };
  }, [activatedTab, allEvents, grading, minutage, showDirtyForm, schedules.programmes]);

  const handleDiscardDirtyForm = () => {
    setIsDirty(false);
    if (next.type === 'tab') handleTabChange(next.value, false);
    if (next.type == 'channel') {
      const args = next.value;
      setSelectedChannel(args.channelId);
      setChannelInfo(args);
      // Save to session
      saveAllLastAccessed({
        channelId: args.channelId,
        channelName: args.channelName,
      });
    }
    setShowDirtyForm(false);
  };

  const handleTabChange = (tab, dirty) => {
    if (dirty) {
      setNext({ type: 'tab', value: tab });
      setShowDirtyForm(true);
    } else {
      setActivatedTab(tab);
      saveAllLastAccessed({ tab: tab });
    }
  };

  const headerComponent = React.useMemo(() => {
    const activatedTabStyle = {
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      border: 'none',
      fontWeight: 'bold',
    };
    const defaultTabStyle = {
      backgroundColor: 'transparent',
      color: '#FAFAFA80',
      border: 'none',
    };
    const headerTabs = ['Program', 'Grading', 'Minutage'];
    return (
      <div>
        {headerTabs.map((tab, index) => (
          <button
            key={index}
            type="button"
            className="btn"
            style={tab === activatedTab ? activatedTabStyle : defaultTabStyle}
            onClick={() => {
              handleTabChange(tab, isDirty);
            }}
          >
            {' '}
            {tab}
          </button>
        ))}
      </div>
    );
  }, [activatedTab, isDirty]);

  const schedulerProps = React.useMemo(() => {
    return {
      heightBuffer: 177,
      // schedule: { start: startDate, end: endDate },
      showHeaderBar: false,
      quickInfoDetails,
      headerComponent,
    };
  }, [headerComponent]);

  const programmeProps = React.useMemo(() => {
    return {
      schedulerProps,
      numberOfWeeks,
      setNumberOfWeeks,
      startDate,
      endDate,
      channelInfo,
      calculateDates,
      isDirty,
      setIsDirty,
      schedules,
      dispatch,
      handleSaveChannelArchitecture,
      setAllEvents
    };
  }, [
    schedulerProps,
    startDate,
    endDate,
    selectedChannel,
    numberOfWeeks,
    isDirty,
    schedules,
    channelInfo,
  ]);

  const gradingProps = React.useMemo(() => {
    return {
      schedulerProps,
      channelInfo,
      startDate,
      endDate,
      calculateDates,
      isDirty,
      setIsDirty,
      setGrading,
      handleSaveGradeArchitecture,
    };
  }, [schedulerProps, isDirty, channelInfo]);

  const minutageProps = React.useMemo(() => {
    return {
      schedulerProps,
      channelInfo,
      startDate,
      endDate,
      calculateDates,
      isDirty,
      setIsDirty,
      setMinutage,
      handleSaveMinutageArchitecture
    };
  }, [schedulerProps, isDirty, channelInfo]);

  const activeCAComponent = React.useMemo(
    () =>
      activatedTab === 'Grading' ? (
        <CAGrading {...gradingProps} />
      ) : activatedTab === 'Minutage' ? (
        <CAMinutage {...minutageProps} />
      ) : (
        <CAProgrammmes {...programmeProps} />
      ),
    [activatedTab, endDate, channelInfo, numberOfWeeks, schedules, isDirty]
  );

  const renderNumericTextbox = React.useCallback(() => {
    return (
      <NumericTextbox
        format="##"
        value={activatedTab === 'Program' ? numberOfWeeks : 1}
        min={1}
        max={52}
        showSpinButton={false}
        onBlur={(args) => {
          setNumberOfWeeks(parseInt(args.target.value));
        }}
        enabled={channelsList.length > 0 ? activatedTab === 'Program' : false}
        cssClass="e-op2mise-numeric-textbox"
        label="Number of Weeks:"
      />
    );
  }, [activatedTab, numberOfWeeks, channelsList]);

  const handleSelectChannel = (args) => {
    if (isDirty) {
      setNext({ type: 'channel', value: args });
      setShowDirtyForm(true);
    } else {
      setSelectedChannel(args.channelId);
      setChannelInfo(args);
      // Save to session
      saveAllLastAccessed({
        channelId: args.channelId,
        channelName: args.channelName,
      });
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    getChannelsAPI();
  }, []);

  return (
    <div>
      <Header
        title={'Architecture'}
        childComponent={
          <div className="d-flex align-items-center gap-4">
            {/* Channels Selection/Dropdown */}
            <div className="architecture-banner-channel-selector">
              <span className="architecture-banner-channel-label">
                Channel:
              </span>
              <div>
                {/** Re-locate this re-usable component to `op2mise-react-widgets */}
                <Select
                  text={
                    channelsList.find(
                      (channel) => channel.channelId === selectedChannel
                    )?.channelName ?? 'Please select a channel'
                  }
                  list={channelsList}
                  onSelect={(args) => handleSelectChannel(args)}
                  width="200px"
                />
              </div>
            </div>

            {/* Number of Weeks Input Field */}
            {/* <div className="architecture-banner-channel-selector">
              <div>{renderNumericTextbox()}</div>
            </div> */}
          </div>
        }
      />
      <div className="architecture-content">
        {/* {isLoading ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div> : <Scheduler {...schedulerProps} />} */}
        {/* {isLoading ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div>
          :  */}
        {activeCAComponent}
        {/* } */}
      </div>
      <ConfirmLeaveModal
        show={showPrompt}
        onSave={() => {
          handleSaveDirty();
          confirmNavigation();
        }}
        onDiscard={confirmNavigation}
        onHide={cancelNavigation}
      />

      <ConfirmLeaveModal
        show={showDirtyForm}
        onSave={() => {
          handleSaveDirty();
          handleDiscardDirtyForm();
        }}
        onDiscard={handleDiscardDirtyForm}
        onHide={() => setShowDirtyForm(false)}
      />
    </div>
  );
};

export default ChannelArchitecture;
