export const applyCellStyle = ({ columnInstance }) => {
  /**
    * This function is designed to add a clear filter button to the last column in an ag-Grid configuration.
    * It iterates through the columns and applies a custom header component to the last column.
    * This is useful for enhancing the user experience by providing a consistent way to clear filters directly
    * from the column header, improving data interaction and visibility.
    */

    return columnInstance.map((column, columnIndex) => {
      if (column.cellDataType) {
        if (column.cellDataType === 'number') {
        return {
          ...column,
          cellStyle: { textAlign: 'right' },
        };
        } else if (column.cellDataType === 'action') {
          // remove cellDatatype
          delete column.cellDataType;
          return {
            ...column,
            cellClass: 'ag-text-align-center suppress-cell-selection-border',
            filter: false,
            headerName: ' ',
            lockVisible: true,
            sortable: false,
            suppressColumnsToolPanel: true,
            suppressHeaderContextMenu: true,
            suppressHeaderMenuButton: true,
            suppressNavigable: true,
            onCellContextMenu: (params) => {
              params.api.hidePopupMenu();
            }
          };
      }
      } 
      return column;
    });
}