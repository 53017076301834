/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { xIcon, downloadFileIcon } from 'assets';
import checkIcon from 'assets/icons/Check.svg';
import SchedulerStorage from 'components/schedule/react-scheduler/SchedulerStorage';

import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import useStore from 'store/AccountStore';
import Button from 'shared/components/button/Button';
import styles from '../../schedule/react-scheduler/forms/import/SuccessModal.module.css';
import { OpDataGrid } from 'op2mise-react-widgets';

export default function ImportResultModalComponent({
  closeModal,
  uploadedList,
  scheduleInfo,
  importResultColumns,
  importResultFileTitle,
}) {
  const { user } = useStore((state) => state);
  const { importFilter } = SchedulerStorage;
  const [filter, setFilter] = useState(importFilter.all);
  const [tableData, setTableData] = useState([]);

  const changeFilter = (selected) => {
    setFilter(selected);
  };

  const filterUploaded = useCallback(() => {
    if (filter === importFilter.all) setTableData(uploadedList);
    else if (filter === importFilter.Imported) setTableData(uploadedList.filter((o) => o.imported));
    else if (filter === importFilter.NotImported) setTableData(uploadedList.filter((o) => !o.imported));
  }, [filter]);


  const exportExcel = () => {
    const { organisationName } = user;
    const excelData = tableData.map((value, index) => {
      const temp = {};
      for (let i = 0; i < importResultColumns.length; i++) {
        temp[importResultColumns[i].headerText] = value[importResultColumns[i].field];
      }
      return { ...temp, ...{ Imported: value.imported, Result: value.result } };
    });

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileName = `${organisationName}_op2mise_${importResultFileTitle}_${scheduleInfo.channelName
      }_${scheduleInfo.period
        .split('-')[0]
        .replaceAll('/', '')
        .replace(' ', '')}_${scheduleInfo.period
          .split('-')[1]
          .replaceAll('/', '')
          .replace(' ', '')}_${String(new Date().getDate()).padStart(
            2,
            '0',
          )}${String(new Date().getMonth() + 1).padStart(
            2,
            '0',
          )}${new Date().getFullYear()}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'Import Results': ws }, SheetNames: ['Import Results'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  // Column definitions for the data grid
  const columnDefinitions = React.useMemo(
    () => {
      const columns = importResultColumns.map((column) => {
        return {
          field: column.field,
          headerName: column.headerText,
          headerToolTip: column.headerText,
          suppressMenu: true,
          suppressNavigable: true,
          cellCss: 'no-border',
          cellStyle: { color: '#656565', textAlign: 'center' },
        }
      });

      const resultColumns = [{
        field: 'imported',
        headerName: " ",
        suppressMenu: true,
        cellCss: 'no-border',
        maxWidth: 80,
        cellRenderer: (params) => (
          <div style={{ textAlign: 'center', }}>
            <img src={params.data.imported ? checkIcon : xIcon} alt="" />
          </div>
        ),
      }, {
        field: 'result',
        headerName: "Results",
        headerToolTip: "Results",
        suppressMenu: true,
        suppressNavigable: true,
        minWidth: 180,
        maxWidth: 180,
        cellCss: 'no-border',
        cellClassRules: {
          'success': params => params.data.imported,
          'danger': params => !params.data.imported
        },

      }]

      if (columns.length) {
        resultColumns.forEach((col) => columns.push(col))
      }

      return columns;
    },
    [importResultColumns]
  );

  // Returns all fields from columnDefinition except the action column
  const headerFields = columnDefinitions
    .map((column) => column.field);

  const handleCustomVerbiage = filter === importFilter.Imported ? 'No successful imports' : filter === importFilter.NotImported ? 'No failed imports' : 'No import results'


  useEffect(() => {
    filterUploaded();
  }, [filter, uploadedList]);

  return (
    <Modal
      size="xl"
      className="modal-widthCustom"
      show
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Body style={{ padding: '35px' }}>
        <div className={styles.divMessage}>
          <b>
            {' '}
            {uploadedList.filter((o) => o.imported).length}
            {' '}
          </b>
          slots out of
          {' '}
          <b>
            {' '}
            {uploadedList.length}
            {' '}
          </b>
          were successfully imported and validated!
        </div>

        <div className={`${styles.successDiv}`}>
          <OpDataGrid
            theme={'SIMPLE'}
            columns={columnDefinitions}
            rows={tableData}
            rowClass='white-background'
            customVerbiage={handleCustomVerbiage}
            gridName="import-results"
            gridHeightBuffer={350}
            resizable={false}
            suppressContextMenu={true}
            suppressMovableColumns={true}
            sortable={false}
            pagination={false}
            columnHoverHighlight={false}
            suppressRowHoverHighlight={true}
            suppressCellSelection={true}
            filter={false}
            sharedColumnProperties={{
              cellStyle: {
                fields: headerFields,
                value: { textAlign: 'left' },
              },
              flex: {
                fields: headerFields,
                value: 1,
              },
              suppressHeaderMenuButton: {
                fields: headerFields,
                value: true,
              },
              headerClass: {
                fields: headerFields,
                value: 'ag-header-text-align-left',
              },
            }}
          />
          {/* <table>
            <thead className={styles.tableHead}>
              <tr className={styles.tableRow} style={{ width: '100%' }}> */}
          {/* <th className={`${styles.tableHeader} ${styles.width22}`}>
                  DAY OF WEEK
                </th>
                <th className={`${styles.tableHeader} ${styles.width13}`}>
                  START TIME
                </th>
                <th className={`${styles.tableHeader} ${styles.width13}`}>
                  END TIME
                </th>
                <th className={`${styles.tableHeader} ${styles.width13}`}>
                  GRADE
                </th> */}
          {/* {importResultColumns.map((column, index) => (
                  <th
                    key={column.field}
                    style={column.field === 'dayOfweek' || column.field === 'dayOfWeek' ? { whiteSpace: 'nowrap', textAlign: 'center !important' } : {}}
                    className={`${styles.tableHeader} ${styles.width22}`}
                  >
                    {column.headerText.toUpperCase()}
                  </th>
                ))}
                <th className={`${styles.tableHeader} ${styles.width6}`} />
                <th className={`${styles.tableHeader} ${styles.width35}`}>
                  RESULTS
                </th>
              </tr>
            </thead>
            <tbody className={`${styles.successBody} ${styles.tableBody}`}>
              {tableData.map((value, index) => (
                <tr
                  key={index}
                  className={`${styles.trBorder} ${styles.tableRow}`}
                  style={{ width: '100%' }}
                >
                  {
                    importResultColumns.map((column, index) => (
                      <td
                        key={column.field}
                        className={`table-body-column ${styles.width22}`}
                      >
                        {value[column.field]}
                      </td>
                    ))
                  } */}
          {/* <td className={`table-body-column ${styles.width21}`}>
                    {value.dayOfweek}
                  </td>
                  <td className={`table-body-column ${styles.width12}`}>
                    {value.startTime}
                  </td>
                  <td className={`table-body-column ${styles.width12}`}>
                    {value.endTime}
                  </td>
                  <td className={`table-body-column ${styles.width12}`}>
                    {value.grade}
                  </td> */}
          {/* <td className={`table-body-column ${styles.width11}`}>
                    <i>
                      <img src={value.imported ? checkIcon : xIcon} alt="" />
                    </i>
                  </td>
                  <td
                    className={`table-body-column  ${styles.bold} ${styles.width32
                      } ${value.result === 'Success'
                        ? styles.success
                        : styles.error
                      }`}
                    title={value.result}
                  >
                    {value.result}
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div >
        <div className={styles.divSelection}>
          <div
            className="form-check"
            style={{ marginRight: '20px', display: 'inline-block' }}
            onClick={() => changeFilter(importFilter.all)}
            onKeyDown={() => changeFilter(importFilter.all)}
            role="presentation"
          >
            <input
              className={`${styles.formCheck} form-check-input`}
              type="radio"
              name="successFilter"
              id="flexRadioDefault1"
              checked={filter === importFilter.all}
            />
            <div className="form-check-label" htmlFor="flexRadioDefault1">
              Show all
            </div>
          </div>

          <div
            className="form-check"
            style={{ marginRight: '20px', display: 'inline-block' }}
            onClick={() => changeFilter(importFilter.Imported)}
            onKeyDown={() => changeFilter(importFilter.Imported)}
            role="presentation"
          >
            <input
              className={`${styles.formCheck} form-check-input`}
              type="radio"
              name="successFilter"
              id="flexRadioDefault2"
              checked={filter === importFilter.Imported}
            />
            <div className="form-check-label" htmlFor="flexRadioDefault2">
              Imported
            </div>
          </div>

          <div
            className="form-check"
            style={{ marginRight: '20px', display: 'inline-block' }}
            onClick={() => changeFilter(importFilter.NotImported)}
            onKeyDown={() => changeFilter(importFilter.NotImported)}
            role="presentation"
          >
            <input
              className={`${styles.formCheck} form-check-input`}
              type="radio"
              name="successFilter"
              id="flexRadioDefault3"
              checked={filter === importFilter.NotImported}
            />
            <div className="form-check-label" htmlFor="flexRadioDefault3">
              Not imported
            </div>
          </div>

          <img
            height="29px"
            style={{ float: 'right', cursor: 'pointer' }}
            title="Download results"
            src={downloadFileIcon}
            alt=""
            onClick={exportExcel}
          />
        </div>

        <div className={styles.divClose}>
          <Button
            text="Close"
            onClick={closeModal}
            style={{ marginRight: '0' }}
          />
        </div>
      </Modal.Body >
    </Modal >
  );
}
